// extracted by mini-css-extract-plugin
export var background_word = "styles-module--background_word--TuRcj";
export var contact_icons = "styles-module--contact_icons--85u5P";
export var contact_item = "styles-module--contact_item--89m01";
export var contact_items = "styles-module--contact_items--Xzj+F";
export var contact_items_img_wrapper = "styles-module--contact_items_img_wrapper--X1s3w";
export var contact_items_title = "styles-module--contact_items_title--dLbsR";
export var contact_us_mail_img_wrapper = "styles-module--contact_us_mail_img_wrapper--JNxz9";
export var contact_us_mail_info = "styles-module--contact_us_mail_info--i9n5q";
export var contact_us_mail_section = "styles-module--contact_us_mail_section--kD5Rm";
export var contact_us_section = "styles-module--contact_us_section--b8auy";