import React, {useRef} from "react";

import Footer from "../../components/Footer/Footer";
import Media from '../../images/Contacts/media.png'
import Dev from '../../images/Contacts/dev.png'
import Investors from '../../images/Contacts/investors.png'
import Res from '../../images/Contacts/res.png'
import Background from '../../images/Contacts/backgroundword.png'
import Mail from '../../images/Contacts/Mail.png'
import * as styles from './styles.module.scss'
import SocialLinks from "../../components/UI/SocialLinks";
import ScrollTopBtn from "../../components/UI/ScrollTopBtn";
import FadeInSection from "../../components/FadeInSection/FadeInSection";
import ContactForm from "../../components/ContactForm/ContactForm";

const Contacts = () => {
    const ref = useRef(null)
    const executeScroll = () => ref.current.scrollIntoView()
    return (
        <div>
            <ScrollTopBtn/>
            <FadeInSection>
                <section className={styles.contact_us_section}>
                    <h1>Contact us</h1>
                    <div className={styles.background_word}>
                        <img src={Background} alt=""/>
                    </div>
                    <div className={styles.contact_items}>
                        <div className={styles.contact_item}>
                            <div className={styles.contact_items_img_wrapper}>
                                <img src={Media} alt=""/>
                            </div>
                            <h3 className={styles.contact_items_title}>
                                From media
                            </h3>
                            <p>and need more information?</p>
                        </div>
                        <div className={styles.contact_item}>
                            <div className={styles.contact_items_img_wrapper}>
                                <img src={Investors} alt=""/>
                            </div>
                            <h3 className={styles.contact_items_title}>
                                An investor
                            </h3>
                            <p>and need more information?</p>
                        </div>
                        <div className={styles.contact_item}>
                            <div className={styles.contact_items_img_wrapper}>
                                <img src={Dev} alt=""/>
                            </div>
                            <h3 className={styles.contact_items_title}>
                                A developer
                            </h3>
                            <p>and want to integrate your app with Unisphere?</p>
                        </div>
                        <div className={styles.contact_item}>
                            <div className={styles.contact_items_img_wrapper}>
                                <img src={Res} alt=""/>
                            </div>
                            <h3 className={styles.contact_items_title}>
                                A resident
                            </h3>
                            <p>and have any suggestions <br/> or problems?</p>
                        </div>
                    </div>
                </section>
            </FadeInSection>
            <FadeInSection>
                <section className={styles.contact_us_mail_section}>
                    <div className={styles.contact_us_mail_info}>
                        <div className={styles.contact_us_mail_img_wrapper}>
                            <img src={Mail} alt=""/>
                        </div>
                        <div>
                            <p>We'd love to hear from you in any cases. Do not hesitate to write us</p>
                            <button onClick={executeScroll}>
                                <h4>
                                    HELLO@UNISPHERE.WORLD
                                </h4>
                            </button>
                        </div>
                    </div>
                    <div ref={ref} className={styles.contact_icons}>
                        <SocialLinks></SocialLinks>
                    </div>
                </section>
            </FadeInSection>
        <FadeInSection>
            <ContactForm />
        </FadeInSection>
            <Footer/>
        </div>
    )
}
export default Contacts
