import React, {useRef, useState} from "react";
import * as styles from './styles.module.scss'
import axios from "axios";
import AcceptModal from "./AcceptModal";


const ContactForm = () => {
    const [userData, setUserData] = useState({})
    const [modalActive, setModalActive] = useState(false)
    const [errorConfirm, setErrorConfirm] = useState(false)
    const screenWidth = typeof window !== "undefined" ? window.innerWidth : ''
    const ref = useRef('')

    const handleUserData = (e) => {
        console.log(e.target.name)
        setUserData(state => ({...state, [e.target.name]: e.target.value}))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (ref.current.checked) {
            setModalActive(true)
            axios.post('https://admin.unisphere.world/send-contact-us.php', {
                data: userData
            })
                .then(function (response) {
                    console.log(response);
                    setUserData(state => ({...state, [e.target.name]: ''}))
                    setTimeout(() => {
                        setModalActive(false)
                    }, 1000)
                })
                .catch(function (error) {
                    console.log(error);
                    setUserData({userName: '', userEmail: '', userMessage: ''})
                    setTimeout(() => {
                        setModalActive(false)
                    }, 1000)
                });
        } else {
            setErrorConfirm(true)
            setTimeout(() => {
                setErrorConfirm(false)
            }, 1000)
        }
    }

    console.log(userData)
    return (
        <div>
            <form action="submit" className={styles.contact_form} onSubmit={handleSubmit}>
                <div className={styles.contact_us_form_container}>
                    <div className={styles.contact_input_user_info}>
                        <input type="text"
                               required
                               placeholder="Enter your name"
                               name={'userName'}
                               onChange={handleUserData}
                               value={userData.userName}/>
                        <input type="email"
                               required
                               placeholder="Enter e-mail"
                               name={'userEmail'}
                               onChange={handleUserData}
                               value={userData.userEmail}/>
                        {screenWidth > 770 ?
                            <div className={`${styles.checkbox_wrapper} ${errorConfirm ? styles.shake : ''}`}>
                                <input type="checkbox"
                                       name="checkbox"
                                       id="checkbox"
                                       value="text"
                                       ref={ref}/>
                                <label htmlFor={"checkbox"}> I agree to the processing of personal data</label>
                            </div> : null}
                    </div>
                    <div className={styles.contact_input_user_questions}>
                        <textarea name="userMessage"
                                  onChange={handleUserData}
                                  placeholder='Enter your message'
                                  id=""
                                  value={userData.userMessage}></textarea>
                        {screenWidth < 770 ? <div className={`${styles.mobile_checkbox_wrapper} ${errorConfirm ? styles.shake : ''}`}>
                            <input type="checkbox"
                                   name="checkbox"
                                   id="checkbox"
                                   value="text"
                                   ref={ref}/>
                            <label htmlFor={"checkbox"}> I agree to the processing of personal data</label>
                        </div> : null}
                        <button type='submit'>Send Message →</button>
                    </div>
                </div>
            </form>
            <AcceptModal checked={modalActive}/>
        </div>
    )
}
export default ContactForm
